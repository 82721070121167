html,
body {
    height: 100%;
}

.breadcrumb-item a {
    color: black;
    text-decoration: underline;
}

.breadcrumb-item a:hover {
    color: #6C757D;
    text-decoration: underline;
}

.slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

.cursor-pointer {
    cursor: pointer;
}
